<template>
  <div>
    <div class="mb-8 ">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
      <div v-if="setor">
        <v-chip
          class="mr-1"
          small
        >
          Setor: {{ setor }}
        </v-chip>
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-text
        v-model="form.nomeColuna"
        class="col-12 col-md-3"
        :label="$t('modulos.kanban_configuracao.formulario.nome_coluna')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.nome_coluna_placeholder')"
        :max="200"
        obrigatorio
        trim
      />

      <input-combobox-text-opcoes
        v-model="form.situacoes"
        :options="opcoes.situacoes"
        class="col-12 col-md-6"
        :label="$t('modulos.kanban_configuracao.formulario.situacoes')"
        obrigatorio
        multiplos
        item-value="id"
        item-text="descricao"
      />

      <input-select
        v-model="form.flagExibirOSTodosSetores"
        class="col-12 col-md-3"
        :options="opcoes.flagExibirOSTodosSetores"
        :label="$t('modulos.kanban_configuracao.formulario.exibir_todos_setores')"
        :placeholder="$t('geral.inputs.selecione')"
      />

      <div class="col-12 col-md-3 d-flex align-center">
        <input-text
          v-model="corHexaFundoColunaComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_coluna')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_coluna')"
          obrigatorio
          trim
        />
        <color-picker
          v-model="form.corHexaFundoColuna"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

      <div class="col-12 col-md-3 d-flex align-center">
        <input-text
          v-model="corHexaFonteCabecalhoCartaoComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_fonte')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_fonte')"
          obrigatorio
          trim
        />
        <color-picker
        v-model="form.corHexaFonteCabecalhoCartao"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

      <div class="col-12 col-md-3 d-flex align-center">
        <input-text
          v-model="corHexaCartaoComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_cartao')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_cartao')"
          obrigatorio
          trim
        />
        <color-picker
          v-model="form.corHexaFundoCartao"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

      <div class="col-12 col-md-3 d-flex align-center">
        <input-text
          v-model="corFonteComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_fonte_cartao')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_fonte_cartao')"
          obrigatorio
          trim
        />
        <color-picker
          v-model="form.corHexaFonteCartao"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

      <input-text
        v-model="form.quantidadeCartoesLinha"
        class="col-12 col-md-3"
        :label="$t('modulos.kanban_configuracao.formulario.quantidade_cartoes_por_linha')"
        :max="200"
        obrigatorio
        type="number"
      />

      <input-text
        v-model="form.quantidadeCartoesColuna"
        class="col-12 col-md-3"
        :label="$t('modulos.kanban_configuracao.formulario.quantidade_cartoes_por_coluna')"
        :max="200"
        obrigatorio
        type="number"
      />

      <input-select
        v-model="form.tamanhoFonte"
        :options="opcoes.tamanhoFonte"
        class="col-12 col-md-3"
        :label="$t('modulos.kanban_configuracao.formulario.tamanho_fonte')"
        obrigatorio
      />

    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import KanbanConfiguracaoEnumeradorSituacoesService from '@common/services/cadastros/KanbanConfiguracaoEnumeradorSituacoesService';
import KanbanConfiguracaoService from '@common/services/cadastros/KanbanConfiguracaoService';
import { KanbanConfiguracaoModel } from '@common/models/cadastros/KanbanConfiguracaoModel';
import SetorService from '@common/services/cadastros/SetorService';
import helpers from '@common/utils/helpers';
import { mapActions } from 'vuex';

export default {
  props: {
    ['id']: { type: [String, Number], default: null, },
  },

  data() {
    return {
      situacoesJaSelecionadas: [],
      situacoesAtual: [],
      valido: false,
      setorId: null,
      setor: null,
      form: new KanbanConfiguracaoModel({}),
      opcoes: {
        classificacao: [],
        situacoes: [],
        flagExibirOSTodosSetores: helpers.BoleanoEnum,
        tamanhoFonte: [
          { value: 6, text: '0.6' },
          { value: 8, text: '0.8' },
          { value: 10, text: '1' },
          { value: 12, text: '1.2' },
          { value: 14, text: '1.4' },
        ],
      },
    };
  },

  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.kanban_configuracao.titulos.editar_coluna');
      return this.$t('modulos.kanban_configuracao.titulos.nova_coluna');
    },
    corHexaFonteCabecalhoCartaoComHashtag: {
      get() {
        if (this.form.corHexaFonteCabecalhoCartao && this.form.corHexaFonteCabecalhoCartao.includes('#'))
          return this.form.corHexaFonteCabecalhoCartao;
        return `#${this.form.corHexaFonteCabecalhoCartao}`;
      },
      set(value) {
        this.form.corHexaFonteCabecalhoCartao = value.replace(/^#/, '');
      },
    },
    corHexaFundoColunaComHashtag: {
      get() {
        if (this.form.corHexaFundoColuna.includes('#'))
          return this.form.corHexaFundoColuna;
        return `#${this.form.corHexaFundoColuna}`;
      },
      set(value) {
        this.form.corHexaFundoColuna = value.replace(/^#/, '');
      },
    },
    corHexaCartaoComHashtag: {
      get() {
        if (this.form.corHexaFundoCartao.includes('#'))
          return this.form.corHexaFundoCartao;
        return `#${this.form.corHexaFundoCartao}`;
      },
      set(value) {
        this.form.corHexaFundoCartao = value.replace(/^#/, '');
      },
    },
    corFonteComHashtag: {
      get() {
        if (this.form.corHexaFonteCartao.includes('#'))
          return this.form.corHexaFonteCartao;
        return `#${this.form.corHexaFonteCartao}`;
      },
      set(value) {
        this.form.corHexaFonteCartao = value.replace(/^#/, '');
      },
    },
    corHexaFonteCabecalhoCartao: {
      get() {
        if (this.form.corHexaFonteCabecalhoCartao && this.form.corHexaFonteCabecalhoCartao.includes('#'))
          return this.form.corHexaFonteCabecalhoCartao;
        return `#${this.form.corHexaFonteCabecalhoCartao}`;
      },
      set(value) {
        this.form.corHexaFonteCabecalhoCartao = value.replace(/^#/, '');
      },
    },
    corHexaFundoColuna: {
      get() {
        if (this.form.corHexaFundoColuna.includes('#'))
          return this.form.corHexaFundoColuna;
        return `#${this.form.corHexaFundoColuna}`;
      },
      set(value) {
        this.form.corHexaFundoColuna = value.replace(/^#/, '');
      },
    },
    corHexaFundoCartao: {
      get() {
        if (this.form.corHexaFundoCartao.includes('#'))
          return this.form.corHexaFundoCartao;
        return `#${this.form.corHexaFundoCartao}`;
      },
      set(value) {
        this.form.corHexaFundoCartao = value.replace(/^#/, '');
      },
    },
    corHexaFonteCartao: {
      get() {
        if (this.form.corHexaFonteCartao.includes('#'))
          return this.form.corHexaFonteCartao;
        return `#${this.form.corHexaFonteCartao}`;
      },
      set(value) {
        this.form.corHexaFonteCartao = value.replace(/^#/, '');
      },
    },
  },

  watch: {
    situacoesJaSelecionadas: function () {
      this.buscarSituacoes();
    },
  },

  mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);

    this.buscarSituacoes();


    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Kanban', 'Editar');
      this.buscar(this.id);
    }

    helpers.redirecionarSemPermissao(this, 'Instrumento', 'Inserir');
    if (!this.id) {
      this.form.setorId = this.$route.params.id;
      this.setorId = this.$route.params.id;
      this.buscarUltimaSequencia();
    } else this.setorId = this.$route.params.setorId;

    this.listarSituacoesJaSelecionadas();

    SetorService.buscar(this.setorId).then((response) => {
      this.setor = response.data.nome;
    });
  },

  methods: {
    ...mapActions(['setAbaKanban']),

    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      KanbanConfiguracaoService.buscar(this.id)
        .then((res) => {
          this.form = new KanbanConfiguracaoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.kanban_configuracao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = this.$refs.form.validate();
        });
    },

    confirmacaoSalvar: function () {
      if (!this.id) {
        this.salvar();
        return;
      }
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },

    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;

      this.$store.dispatch('Layout/iniciarCarregamento');

      KanbanConfiguracaoService.salvar(this.form.request)
      .then(() => {
        this.setAbaKanban(0);
        this.$router.push({
          name: 'kanban-configuracao',
          params: { setorId: this.setorId },
        });
        this.toastSucesso(
          this.$t(`modulos.kanban_configuracao.cadastro_sucesso`)
        );
      })
      .catch((err) => {
        this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
    },

    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.setAbaKanban(0);
        this.$router.push({
          name: 'kanban-configuracao',
          params: { setorId: this.setorId },
        });
      });
    },

    buscarSituacoes: async function () {
      KanbanConfiguracaoEnumeradorSituacoesService.buscar('situacoes').then(
        (response) => {
          this.opcoes.situacoes = response.filter(({ id }) => {
            return !this.situacoesJaSelecionadas.includes(id);
          });
        }
      );
    },

    buscarUltimaSequencia() {
      if (this.id) return;
      KanbanConfiguracaoService.ultimaSequencia(this.$route.params.id).then(
        (response) => {
          if (response.data.items.length === 0) {
            this.form.sequencia = 1;
            return;
          }
          const objetoComMaiorSequencia = response.data.items.reduce(
            (objetoA, objetoB) => {
              if (objetoA.sequencia > objetoB.sequencia) {
                return objetoA;
              } else {
                return objetoB;
              }
            }
          );
          this.form.sequencia = ++objetoComMaiorSequencia.sequencia;
        }
      );
    },

    listarSituacoesJaSelecionadas: async function (
      paginaAtual = 1,
      porPagina = 200000
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        setorId: this.setorId,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');

      KanbanConfiguracaoService.listar(parametros)
        .then((res) => {
          this.situacoesJaSelecionadas = res.data.items
            .map((item) => item.situacoes)
            .flat();
          if (this.id) {
            this.situacoesAtual = this.form.situacoes.flat();

            this.situacoesJaSelecionadas = this.situacoesJaSelecionadas.filter(
              (item) => !this.situacoesAtual.includes(item)
            );
          }

          this.limparVaziosTabela();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    limparVaziosTabela: function () {
      this.situacoesJaSelecionadas = this.situacoesJaSelecionadas.filter(
        (el) => el
      );
    },
  },
};
</script>

<style scoped>
.p-colorpicker-preview {
  width: 40px !important;
  height: 40px !important;
}

.div-colorpicker {
  display: flex;
  padding: 0;
}
</style>
