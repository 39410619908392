import cloneDeep from 'lodash/cloneDeep';

export class KanbanConfiguracaoModel {
  constructor({
    setorId,
    id,
    sequencia,
    nomeColuna,
    situacoes,
    quantidadeCartoesLinha = 2,
    quantidadeCartoesColuna = 10,
    tamanhoFonte = 10,
    corHexaFundoColuna = "ffffff",
    corHexaFonteCartao = "000000",
    corHexaFundoCartao = "ffffff",
    corHexaFonteCabecalhoCartao = "000000",
    flagExibirOSTodosSetores = false,

  }) {
    this.id = id;
    this.setorId = setorId;
    this.sequencia = sequencia;
    this.nomeColuna = nomeColuna;
    this.situacoes = situacoes;
    this.quantidadeCartoesLinha = quantidadeCartoesLinha;
    this.quantidadeCartoesColuna = quantidadeCartoesColuna;
    this.tamanhoFonte = tamanhoFonte;
    this.corHexaFundoColuna = corHexaFundoColuna;
    this.corHexaFonteCartao  = corHexaFonteCartao;
    this.corHexaFundoCartao = corHexaFundoCartao;
    this.corHexaFonteCabecalhoCartao = corHexaFonteCabecalhoCartao;
    this.flagExibirOSTodosSetores = flagExibirOSTodosSetores;
  }

  get request() {
    const retorno = cloneDeep(this);

    if (this.corHexaFundoColuna) {
      if (this.corHexaFundoColuna.includes("#")) {
        retorno.corHexaFundoColuna = this.corHexaFundoColuna;
      } else
        retorno.corHexaFundoColuna = `#${this.corHexaFundoColuna}`;
    }

    if (this.corHexaFonteCartao){
      if (this.corHexaFonteCartao.includes("#")) {
        retorno.corHexaFonteCartao = this.corHexaFonteCartao;
      } else
        retorno.corHexaFonteCartao = `#${this.corHexaFonteCartao}`;
    }

    if (this.corHexaFundoCartao){
      if (this.corHexaFundoCartao.includes("#")) {
        retorno.corHexaFundoCartao = this.corHexaFundoCartao;
      } else
        retorno.corHexaFundoCartao = `#${this.corHexaFundoCartao}`;
    }

    if (this.corHexaFonteCabecalhoCartao){
      if (this.corHexaFonteCabecalhoCartao.includes("#")) {
        retorno.corHexaFonteCabecalhoCartao = this.corHexaFonteCabecalhoCartao;
      } else
        retorno.corHexaFonteCabecalhoCartao = `#${this.corHexaFonteCabecalhoCartao}`;
    }

    if (this.id)
      retorno.id = this.id

    return retorno;
  }
}
